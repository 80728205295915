import axios, { AxiosInstance } from "axios";
import { DpimSourceDataDTO } from "../types/Dpim";

const BASE_URL = window.MLBBest.envVariables.DPIM_URL;

const DpimClient: AxiosInstance = axios.create({
  baseURL: BASE_URL + "/api",
});

export const getDpimSourceMatches = async (dplId: number): Promise<DpimSourceDataDTO[]> =>
  (await DpimClient.get(`/dpl/external/matches/${dplId}`)).data;

export default DpimClient;
